/* @import url('https://fonts.googleapis.com/css?family=Numans'); */
textarea.form-control {
    color: black;
}


.modal-body {
    color: black;
}


.spinner{
    margin-left: auto;
    margin-right: auto;
    margin-top : 20px;
    margin-bottom : 20px;
}
label {
    padding-left : 10px;
}

ul.pagination {
    padding-top: 10px;
    justify-content: center;
}

.table {
    overflow: auto;
    display: block;
    table-layout: auto;
}

.css-v73v8k {
    color: black
}

.css-15k3avv {
    color: black;
    font-size: 9px;
}

.login-page {
    width : 100%;
    margin-top: auto;
    margin-bottom: auto;
    align-content: center;
}

.card .card-body {
    padding: 5px;
}

.col-lg-2 .card-chart.card {
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.3);
  }
  

.form-group .input-group-prepend .input-group-text, .form-group .input-group-append .input-group-text, .input-group .input-group-prepend .input-group-text, .input-group .input-group-append .input-group-text {
    padding: 10px 18px 10px 18px;
}

.text-primary {
    color: white
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info:active:focus, .btn-info:active:hover, .btn-info.active:focus, .btn-info.active:hover {
    background-color: #3358f4 !important;
    background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8) !important;
    background-image: -moz-linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8) !important;
    color: #ffffff;
    box-shadow: none;
}

.btn-info.btn-simple {
    color: #b72c5c;
    border-color: #b72c5c;;
    background: transparent;
}

.col-sm-10.col-md-10.col-lg-10 {
    margin: auto;
}

.col-sm-12.col-md-6.col-lg-4 {
    margin-left: auto;
    margin-right: auto;
}

/* h4.card-title {
    margin-top: 25px;
} */

text.recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 11px;
}

.titleName {padding-top: 25px;}

.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
    width: 100%;
    min-height: 100%;
    max-height: calc(100vh - 705px);
    z-index: 4;
    position: relative;
    background: #27293d;
    overflow: auto;
  }

  .d-flex.justify-content-center.h-100.login-page {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: auto;
}

@media only screen and (max-width: 480px) {
    .d-flex.justify-content-center.h-100.login-page {
        margin-left: auto;
        margin-right: auto;
        margin-top: 150px;
        margin-bottom: auto;
    }
} 
    
td.Total-data {
    background-color: #211C2d;
}



option {
    color: black;
}

  
h3.card-title {
    font-size: 20px;
}

a.navbar-brand {
    font-weight: 400;
}

p.d-inline {
    font-weight: 400;
    font-size: 9px;
}

.card {
    background: #27293d;
    border: 0;
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
}

i {
    font-weight: 500;
}


/* html,body , #root{
background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');
background-size: cover;
background-repeat: no-repeat;
height: 100%;
font-family: 'Numans', sans-serif;
}

.container{
height: 100%;
align-content: center;
}

.card{
height: 370px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
background-color: rgba(0,0,0,0.5) !important;
}

.social_icon span{
font-size: 60px;
margin-left: 10px;
color: #FFC312;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

.input-group-prepend span{
width: 50px;
background-color: #FFC312;
color: black;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}

.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}

.login_btn{
color: black;
background-color: #FFC312;
width: 100px;
}

.login_btn:hover{
color: black;
background-color: white;
}

.links{
color: white;
}

.links a{
margin-left: 4px;
} */