#notfound {
    position: relative;
    height: 100vh;
  }

  textarea.form-control {
    color: black;
}

  
  #notfound .notfound {

    /* left: 50%;
    top: 50%; */
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    width: 100%;
    line-height: 1.4;
    text-align: center;
    margin-top: 200px;
  }
  
  .notfound .notfound-404 {
    height: 240px;
  }
  
  .notfound .notfound-404 h1 {
    font-size: 140px;
    font-weight: 900;
    margin-top: 15px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0px;
    
  }
  
  .notfound .notfound-404 h1>span {
    text-shadow: -8px 0px 0px #fff;
  }
  
  .notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }
  
  .notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color:white;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 200px;
    }
    .notfound .notfound-404 h1 {
      font-size: 200px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound {
      margin-top: 100px;
    }
    .notfound .notfound-404 {
      height: 162px;
    }
    .notfound .notfound-404 h1 {
      font-size: 140px;
      height: 150px;
      line-height: 162px;
    }
    .notfound h2 {
      font-size: 16px;
    }
  }